const translateEnglishToRussia = (string:string) => {
  const symbols:Record<string, string> = {
    q: 'й',
    w: 'ц',
    e: 'у',
    r: 'к',
    t: 'е',
    y: 'н',
    u: 'г',
    i: 'ш',
    o: 'щ',
    p: 'з',
    '[': 'х',
    '{': 'Х',
    ']': 'ъ',
    '}': 'Ъ',
    '|': '/',
    '`': 'ё',
    '~': 'Ё',
    a: 'ф',
    s: 'ы',
    d: 'в',
    f: 'а',
    g: 'п',
    h: 'р',
    j: 'о',
    k: 'л',
    l: 'д',
    ';': 'ж',
    ':': 'Ж',
    "'": 'э',
    '"': 'Э',
    z: 'я',
    x: 'ч',
    c: 'с',
    v: 'м',
    b: 'и',
    n: 'т',
    m: 'ь',
    ',': 'б',
    '<': 'Б',
    '.': 'ю',
    '>': 'Ю',
    '/': '.',
    '?': ',',
    '@': '"',
    '#': '№',
    $: ';',
    '^': ':',
    '&': '?'
  };

  const newString:string[] = [];
  string.split('').forEach((sybmol) => {
    if (symbols[sybmol]) {
      newString.push(symbols[sybmol]);
    } else {
      newString.push(sybmol);
    }
  });

  return newString.join('');
};

export {
  translateEnglishToRussia
};
