<template>
  <Teleport to="body">
    <div class="modal city-popup__root">
      <div class="modal__body ">
        <button
          class="modal__close"
          @click="closeSelectCityModal"
        >
          <img
            src="~/assets/img/svg/close-gray.svg"
            alt="close icon"
          >
        </button>
        <div class="city-popup__title">Выберите город</div>
        <div class="local-modal__body">
          <div class="local-modal__input">
            <input
              v-model="searchStr"
              placeholder="Начните вводтить ваш город"
              class="input"
              type="text"
            >
          </div>
          <section class="section-search">
            <div
              v-for="(section) in searchCityList"
              :key="`section-search-${section}`"
              class="section-search__list"
              @click="setCity(section)"
            >
              {{ section.name }}
            </div>
          </section>
        </div>
      </div>
      <div
        class="modal__overlay"
        @click="closeSelectCityModal"
      ></div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import useSitisSelectCity from '#sitis/composables/useSitisSelectCity';
import { translateEnglishToRussia } from '~/utils';
import type { ICity } from '#sitis/internal/models/cities/Cities';
import { useCitySelectModal } from '#imports';

const { getCitiesList, citiesList, setCurrentCity } = useSitisSelectCity();
const searchStr = ref<string>('');
const { closeSelectCityModal } = useCitySelectModal();
const searchCityList = computed(():ICity[] => {
  if (!searchStr.value) {
    return [];
  }

  const search = translateEnglishToRussia((searchStr.value || '').toLowerCase());

  return (citiesList.value?.cities || []).filter((city) => {
    const name = (city?.name || '').toLowerCase();
    return Boolean(name.startsWith(search));
  });
});
const setCity = async (city:ICity) => {
  await setCurrentCity(city);
  window.location.reload();
};

onMounted(async () => {
  await getCitiesList();
});
</script>

<style scoped lang="scss">
.city-popup__root{
  .modal__body{
    max-width: 680px;
    padding: 0;
    border-radius:5px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.modal__close{
  width: 14px;
  height: 14px;
  top: 20px;
  color: white;
}

.city-popup__title{
  padding: 20px;
  background: red;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
}

.local-modal__body{
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.local-modal__input{
  & > input{
    height: 58px;
  }
}

.section-search{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px 23px;
}

.section-search__list{
  cursor:pointer;

  &::first-letter{
    font-weight: 700;
  }

  &:hover{
    font-weight: 700;
  }
}
</style>
